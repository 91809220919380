import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
var Container = styled.div.withConfig({
  displayName: "Container",
  componentId: "sc-a4fxlm-0"
})(["max-width:1200px;width:100%;padding:0 10px;margin-right:auto;margin-left:auto;box-sizing:border-box;", ";", " ", ""], function (_ref) {
  var mediaDesktop = _ref.theme.mediaDesktop;
  return mediaDesktop(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    padding-inline-end: ", ";\n  "])), function (props) {
    return props.isSticky ? '440px' : '10px';
  });
}, function (_ref2) {
  var mediaTablet = _ref2.theme.mediaTablet;
  return mediaTablet(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    max-width: 768px;\n    padding: 0 9px;\n  "])));
}, function (_ref3) {
  var mediaMobile = _ref3.theme.mediaMobile;
  return mediaMobile(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    max-width: 360px;\n    padding-right: 10px;\n  "])));
});
Container.propTypes = {
  isSticky: PropTypes.bool
};
export default Container;