/* eslint-disable react/prop-types */

import React, { createContext } from 'react';

export const SecurityContext = createContext({});

const SecurityProvider = ({ value, children }) => {
  return (
    <SecurityContext.Provider value={value}>
      {children}
    </SecurityContext.Provider>
  );
};

export default SecurityProvider;
